<template>
  <div class="page">
    <Header />
    <div class="container" v-if="getCurrentPromotion">
      <div class="h5 font-weight--sm--600 text--sm--center mb--sm--8 mb--md--15 mt--sm--8 mt--md--15" v-if="getCurrentPromotion.promotionName">{{getCurrentPromotion.promotionName}}</div>

       <p><strong>Thời gian khuyến mãi:</strong> <span v-if="getCurrentPromotion.validFrom">{{$moment(getCurrentPromotion.validFrom).format('DD/MM/YYYY')}}</span> - <span v-if="getCurrentPromotion.validTo">{{$moment(getCurrentPromotion.validTo).format('DD/MM/YYYY')}}</span></p>
         <p v-if="getCurrentPromotion.promotionCode"><strong>Code chương trình:</strong> {{getCurrentPromotion.promotionCode}}</p>
        <div v-if="getCurrentPromotion.applyTo">
          <h5>Đối tượng áp dụng</h5>
          <p>{{getCurrentPromotion.applyTo}}</p>
        </div>
        <div v-if="getCurrentPromotion.content">
          <h5>Thể lệ chương trình</h5>
          <pre v-html="getCurrentPromotion.content"></pre>
        </div>
        <div v-if="getCurrentPromotion.productList">
          <h5>Sản phẩm áp dụng</h5>
          <pre v-html="getCurrentPromotion.productList"></pre>
        </div>
    </div>
    <Footer />
    <FooterMobile/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import FooterMobile from '@/components/FooterMobile.vue'
export default {
  components: {
    Header,
    Footer,
    FooterMobile
  },
  data () {
    return {}
  },
  computed: {
    getCurrentPromotion () {
      return this.$store.getters['promotion/getCurrentPromotion'](this.$route.params.promotionCode)
    }
  },
  mounted () {
    this.$store.dispatch('setHeaderMobile', {
      heading: false,
      headingText: '',
      back: true,
      search: false,
      notify: true,
      cart: true
    })
    this.$store.dispatch('setFooterMobile', false)
    this.$store.dispatch('promotion/getPromotions')
  }
}
</script>
